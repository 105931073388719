(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/tipssm-participate-dialog/tipssm-participate-dialog.js') >= 0) return;  svs.modules.push('/components/sport/tipssm-participate-dialog/tipssm-participate-dialog.js');

'use strict';

const {
  getDisplayName,
  getProductClassName
} = svs.utils.products;
const {
  getPlayerCompetition,
  getCompetitionById,
  getCompetitionDrawStatus
} = svs.components.sport.tipssmParticipateDialog;
const {
  resolveCompetitionName
} = svs.components.marketplaceCompetition.helpers;
const infoLink = 'https://cdn1.svenskaspel.net/content/cms/documents/a533ab67-caed-4f92-992f-a86863ef751a/1.27/information-om-behandling-av-personuppgifter-fran-svenska-spel-sport--casino-ab.pdf';
function tipssmParticipateDialog(_ref) {
  let {
    productId,
    competition = null,
    competitionId,
    drawNumber,
    onParticipate,
    onRefuse
  } = _ref;
  if (competitionId === 0) {
    onRefuse(false);
    return;
  }
  const applyCompetitionLogic = competition => {
    if (!competition) {
      onRefuse(false);
      return;
    }
    const {
      compDesc,
      signupStartTime
    } = competition;
    let numDraws;
    let firstDrawNumber;
    let startTime;
    if (competition.tipsSMDetails) {
      numDraws = competition.tipsSMDetails.numDraws;
      firstDrawNumber = competition.tipsSMDetails.firstDrawNumber;
      startTime = competition.tipsSMDetails.startTime;
    } else {
      numDraws = competition.details.numDraws;
      firstDrawNumber = competition.details.firstDrawNumber;
      startTime = competition.details.startTime;
    }
    const maxDraws = firstDrawNumber + numDraws;
    if (drawNumber < firstDrawNumber || drawNumber >= maxDraws) {
      onRefuse(false);
      return;
    }
    getCompetitionDrawStatus(competitionId, participatedDraws => {
      if (participatedDraws.indexOf(drawNumber) !== -1) {
        onRefuse(false);
        return;
      }
      getPlayerCompetition(competitionId, playerCompetition => {
        let isParticipating = true;
        if (!playerCompetition) {
          const startSignup = new Date(signupStartTime);
          const _startTime = new Date(startTime);
          const now = new Date();
          if (now < startSignup && now >= _startTime) {
            onRefuse(false);
            return;
          }
          isParticipating = false;
        }
        if (!isParticipating && firstDrawNumber !== drawNumber) {
          onRefuse(false);
          return;
        }
        const moreInfoLink = "/".concat(getProductClassName(productId), "/tavlingar/").concat(resolveCompetitionName(competition), "/om");
        let message = "<p>Med 64-radersspel p\xE5 ".concat(getDisplayName(productId), " kan du vara med i ").concat(resolveCompetitionName(competition), ".</p>");
        if (isParticipating) {
          message += "<p>L\xE4s mer <a href=\"".concat(moreInfoLink, "\" target=\"_blank\" aria-label=\"L\xE4s mer om ").concat(resolveCompetitionName(competition), "\">om ").concat(resolveCompetitionName(competition), " h\xE4r!</a></p>");
        }
        if (!isParticipating) {
          const rulesAndTermsLink = '/villkor-och-integritet';
          message += "<p>\n            Genom att g\xE5 med i ".concat(resolveCompetitionName(competition), " godk\xE4nner du Regler och  <a href=\"").concat(rulesAndTermsLink, "\" target=\"_blank\" aria-label=\"Villkor f\xF6r ").concat(resolveCompetitionName(competition), "\">Villkor f\xF6r ").concat(resolveCompetitionName(competition), "</a>.\n            </p>");
          message += "<p>\n            Information g\xE4llande <a href=".concat(infoLink, " target=\"_blank\" aria-label=\"Behandling av personuppgifter\">Behandling av personuppgifter</a>\n            </p>");
        }
        svs.components.dialog.api.add(new svs.components.dialog.Confirm({
          branding: svs.components.dialog.branding.SPORT,
          icon: 'nav-games',
          title: "Vill du delta med detta spel i ".concat(resolveCompetitionName(competition), "?"),
          message: [{
            type: svs.components.dialog.message.TEXT,
            text: message
          }],
          actions: [{
            title: 'Nej tack',
            callback: () => {
              svs.components.dialog.api.close();
              onRefuse(true);
            }
          }, {
            title: isParticipating ? "Anv\xE4nd i ".concat(resolveCompetitionName(competition)) : "Delta i ".concat(resolveCompetitionName(competition)),
            callback: () => {
              svs.components.dialog.api.close();
              onParticipate(compDesc, !isParticipating);
            },
            showLoader: true
          }]
        }));
      });
    });
  };
  if (competition) {
    applyCompetitionLogic(competition);
  } else {
    getCompetitionById(competitionId, applyCompetitionLogic);
  }
}
setGlobal('svs.components.sport.tipssmParticipateDialog.tipssmParticipateDialog', tipssmParticipateDialog);

 })(window);