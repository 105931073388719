(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/tipssm-participate-dialog/get-competition-drawstatus.js') >= 0) return;  svs.modules.push('/components/sport/tipssm-participate-dialog/get-competition-drawstatus.js');

'use strict';

const {
  jupiter
} = svs.core;
function getCompetitionDrawStatus(competitionId, callback) {
  jupiter.get("/player/1/competitions/".concat(competitionId, "/drawstatus"), response => {
    if (!response.participatedDraws) {
      callback([]);
    } else {
      callback(response.participatedDraws);
    }
  }, () => {
    callback([]);
  });
}
setGlobal('svs.components.sport.tipssmParticipateDialog.getCompetitionDrawStatus', getCompetitionDrawStatus);

 })(window);