(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/tipssm-participate-dialog/get-competition-by-id.js') >= 0) return;  svs.modules.push('/components/sport/tipssm-participate-dialog/get-competition-by-id.js');

'use strict';

const {
  jupiter
} = svs.core;
function getCompetitionById(competitionId, callback) {
  jupiter.get("/competition/1/competitions/".concat(competitionId), response => {
    if (response.competition) {
      callback(response.competition);
    } else {
      callback(null);
    }
  }, () => {
    callback(null);
  });
}
setGlobal('svs.components.sport.tipssmParticipateDialog.getCompetitionById', getCompetitionById);

 })(window);