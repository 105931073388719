(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/tipssm-participate-dialog/get-player-competition.js') >= 0) return;  svs.modules.push('/components/sport/tipssm-participate-dialog/get-player-competition.js');

'use strict';

const {
  jupiter
} = svs.core;
function getPlayerCompetition(competitionId, callback) {
  jupiter.get('/player/1/competitions?activeOnly=true', response => {
    if (!Array.isArray(response.competitions)) {
      callback(null);
      return;
    }
    const playerCompetitions = response.competitions.filter(competition => competition.competitionId === competitionId);
    if (playerCompetitions.length === 1) {
      callback(playerCompetitions[0]);
    } else {
      callback(null);
    }
  }, () => {
    callback(null);
  });
}
setGlobal('svs.components.sport.tipssmParticipateDialog.getPlayerCompetition', getPlayerCompetition);

 })(window);